import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Utente } from 'src/app/models/latitude/utente.model';


@Injectable({
  providedIn: 'root',
})
export class UtentiService{
  apiUrl: string = environment.latitudeBE.apiUrl +"utente";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
   //   'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkVXR0x3M2tCcGJOUjFJWklEM1B6UyJ9.eyJpc3MiOiJodHRwczovL2xhdGl0dWRlLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiIyekF6WnBhUXRNc2tidUIxaFhYdG5mM2J5Mno2YXlvaUBjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9sYXRpdHVkZS1iZS5tYXAtaG9zdGluZy5pdCIsImlhdCI6MTcyNTk2OTc4NCwiZXhwIjoxNzI2MDU2MTg0LCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMiLCJhenAiOiIyekF6WnBhUXRNc2tidUIxaFhYdG5mM2J5Mno2YXlvaSJ9.csBPHEinN8rlSVfw1pEw0pa9ssFBvaN1XsL_DfQpGdj4-XBz3_N_UrYv2hHMPQ_chguUUf58I-6ODvvG5ecdOLRUYlpe_QVmPSQF7AliUP8yMInJcUDKMPsy8aiC3L8I8gWOhlfqVmfpgst67QvMgMGSldfuSUKgo2I6TqQCoRGDtwS_6sCMqakoUUG6YGePgfpTcS7h6H4rUmGRrCzbDInXbyufxDaQ_8YCO7_z-DjgEpb5MwiF109Wepti_Gru4DoK79yIrCUAJfl3d8VLSrMsRivQfb24aaNJvDzPW7ytkqmFFkRShhVS27fOnSANv91L44Ge59aTcQcd9lwqtg`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }
  getUtenti(): Observable<any> {
    const url =this.apiUrl+"?filter=utenti";
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }

  getByIdAuth0(idAuth0:String): Observable<any> {
    const url =this.apiUrl+"/auth0/"+idAuth0;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }

  getByMail(mail:String): Observable<any> {
    const url =this.apiUrl+"/mail/"+mail;
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(url, httpOptions);
  }


  crea(utente:Utente): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, utente, httpOptions);

  }


  modifica(utente:Utente): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+utente.id, utente, httpOptions);
  }

  elimina(idUtente:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idUtente, httpOptions);

  }


}
