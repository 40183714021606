import { OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { CDUConfigService } from '../services/cdu/cduConfig.service';
import { CDUConfig } from '../models/cdu/cduConfig.model';
import { Comune } from '../models/cdu/comune.model';
import { style } from '@angular/animations';
import { Menu } from 'primeng/menu';
import { UserService } from '../services/user.service';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private userService:UserService,
        private logger: NGXLogger,

        ) {
        }

    ngOnInit() {

        this.model = [
            {
                label: 'HOME',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
                    { label: 'Libreria Servizi', icon: 'pi pi-fw pi-book', routerLink: ['/underconstruction'] }
                ]
            },
            ...(this.userService.isAdmin || this.userService.isSuperAdmin ? [{
                label: 'ADMIN',
                items: [
                    { label: 'Utenti', icon: 'pi pi-fw pi-users', routerLink: ['/utenti'] },
                    { label: 'Servizi', icon: 'pi pi-fw pi-credit-card', routerLink: ['/servizi'] },
                    { label: 'Crediti', icon: 'pi pi-fw pi-money-bill', routerLink: ['/gestione-crediti'] },
                    { label: 'Stato API', icon: 'pi pi-fw pi-microchip', routerLink: ['/api'] }
                ]
            }] : []),
            {
                label: 'SERVIZI',
                items: [
                    { label: 'Geocoder',                icon: 'pi pi-fw pi-map-marker',     routerLink: ['/geocoder'] },
                    { label: 'CDU',
                        icon: 'pi pi-fw pi-building',
                        items:[
                            {
                                label: 'Configurazione',
                                icon: 'pi pi-fw pi-cog',
                                routerLink: ['/cdu/config']
                            },
                            {
                                label: 'Estratti Urbanistici',
                                icon: 'pi pi-fw pi-list',
                                routerLink: ['/cdu/eu']
                                //items:this.euItems
                            },
                        ] },

                    {
                        label: 'Catasto',
                        icon: 'pi pi-fw pi-calculator',
                        items:[
                            {
                                label: 'Prospetti',
                                icon: 'pi pi-fw pi-address-book',
                                routerLink: ['/catasto/prospetti']
                                //items:this.euItems
                            },
                            {
                                label: 'Espropri',
                                icon: 'pi pi-fw pi-minus-circle',
                                routerLink: ['/catasto/espropri']
                                //items:this.euItems
                            }
                        ]
                    },
                    { label: 'Visualizzatore',          icon: 'pi pi-fw pi-map',
                        items:[

                            ...((this.userService.isAdmin || this.userService.isSuperAdmin) ? [
                            {
                                label: 'Qgis Server',
                                icon: 'pi pi-fw pi-server',
                                routerLink: ['/visualizzatore/servers']
                            },
                            {
                                label: 'Ambienti',
                                icon: 'pi pi-fw pi-globe',
                                routerLink: ['/visualizzatore']
                            },

                            ] : [
                                {
                                label: 'Progetti',
                                icon: 'pi pi-fw pi-list',
                                routerLink: ['/visualizzatore/projects']
                                // items:this.euItems
                                }
                            ]),



                        ]
                     },

                ]
            },
            {
                label: 'CREDITI',
                items: [
                    { label: 'Ordini',          icon: 'pi pi-fw pi-cart-plus', routerLink: ['/crediti/ordini'], badge: 'NEW' },
                    { label: 'Transazioni',     icon: 'pi pi-fw pi-chart-bar', routerLink: ['/crediti/transazioni'] },
                ]
            },
            {
                label: 'HELP',
                items: [
                    { label: 'FAQ', icon: 'pi pi-fw pi-question', routerLink: ['/utilities/icons'] },
                    { label: 'Contattaci', icon: 'pi pi-fw pi-envelope', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
                ]
            },

        ];
    }




}
