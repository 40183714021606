import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Utente } from 'src/app/models/latitude/utente.model';


@Injectable({
  providedIn: 'root',
})
export class CreditoService{
  apiUrl: string = environment.latitudeBE.apiUrl +"credito";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  getCreditoUtente(idAuth0:string): Observable<any> {
    const headers = {
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"?utente="+idAuth0, httpOptions);
  }


  modifica(utente:Utente): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+utente.id, utente, httpOptions);
  }

  elimina(idUtente:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idUtente, httpOptions);

  }


}
