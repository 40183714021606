<div class="layout-topbar">
    <a class="layout-topbar-logo mr-3" routerLink="">

        <img src="assets/loghi/{{layoutService.config().colorScheme === 'light' ? 'logo_latitude' : 'logo_latitude'}}.png" alt="logo">
        <!-- <span style="color: #2c81d0;" class="mt-2 font-['Poppins'] font-bold">LATITUDE</span> -->
    </a>
    <ng-container *ngIf="authService.user$ | async as user; else showLogin">
        <button #menubutton style="color: #2c81d0;" class="p-link layout-menu-button layout-topbar-button mt-2" (click)="layoutService.onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <!-- <span class="mt-1 p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" placeholder="Cerca..."  class="w-full sm:w-auto"/>
        </span> -->
        <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
            <p-button *ngIf="userService.isUtente" class="mt-1 ml-8" label="{{this.creditChip}}" [outlined]="true" severity="{{severityCredito}}" icon="pi pi-credit-card"/>
            <p-chip class="mt-2" *ngIf="!userService.isUtente">
                <div class="p-2 font-bold" style="color:#6FB4FF">ADMIN</div>
            </p-chip>
<!--             <p-button *ngIf="" class="mt-1 ml-8" label="ADMIN" [outlined]="true" severity="info" icon="pi pi-crown"/>
 -->
            <button class="p-link layout-topbar-button">
                <i class="pi pi-inbox"></i>
                <span>Calendar</span>
            </button>
         <!--    <button class="p-link layout-topbar-button" >
                <i class="pi pi-cog"></i>
                <span>Settings</span>
            </button>
            <button class="p-link layout-topbar-button">
                <i class="pi pi-user"></i>
                <span>Profilo</span>
            </button> -->
            <button class="p-link layout-topbar-button" (click)="logout()"  pTooltip="Logout">
                <i class="pi pi-sign-out"></i>
                <span>Logout</span>
            </button>
        </div>
    </ng-container>
    <ng-template #showLogin>
        <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button" (click)="login()" pTooltip="Login">
            <i class="pi pi-sign-in"></i>
            <span>Login</span>
        </button>
        </div>
    </ng-template>

</div>
